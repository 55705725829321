.page-header {
  .has-shapes {
    .shape-bike {
      left: 5px;
      top: 5px;
      width: 75px;
    }
  }
}

.sponsor-logo {
  height: 300px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map {
  width: 100%;
  height: 500px;
}

.minimap {
  width: 100%;
  height: 200px;
}

.elevation-profile {
  width: 100%;
  height: 300px;
}

.kalender-section {
  padding-top: 40px;
  padding-bottom: 40px;
}
.ol-attribution{
  li, ol{
    margin-bottom: 0;
    padding-left: 0;
  }
  ul{
    margin-bottom: 0;
  }
  ul:not(.nav){
    li{
      padding-left: 0;
    }
    li::before{
     content: none;
    }
  }
}
