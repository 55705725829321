/*  typography */
body {
  line-height: 1.7;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  font-weight: 400;
  color: $text-color;
}

p, .paragraph {
  font-size: 16px;
}

.lead {
  color: $text-color-light;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
  color: $text-color-dark;
  font-family: $primary-font;
  font-weight: 700;
}

h1, .h1{
  font-size: 44px;
  @include tablet {
    font-size: 38px;
  }
}

h2, .h2{
  font-size: 36px;

  @include tablet {
    font-size: 30px;
  }
}

h3, .h3{
  font-size: 24px;
}

h4, .h4{
  font-size: 22px;
}

h5, .h5{
  font-size: 18px;
}

h6, .h6{
  font-size: 14px;
}
